window.DoradoAlerts = window.DoradoAlerts || {};

window.DoradoAlerts.AlertDropdown = (function() {

  /**
   * This component includes a button activating a dropdown menu containing alerts.
   */
  class AlertDropdown extends React.Component {

    /**
     * Constructor for the <AlertDropdown /> component
     * @param {*} props - Props passed to the <AlertDropdown /> component
     * @param {object[]} props.alerts - Alerts to display in the dropdown menu
     * @param {string|function} props.buttonText - Text to display in the button.
     *   If a function is passed, it will be called with the alerts. The function
     *   should return a string or JSX. Optional. Defaults to:
     *   alerts => `Total: ${alerts.length}` (Ex. Total: 3)
     * @param {string} props.className - A custom CSS class for the component. Optional.
     *   Defaults to: 'dde-alert__dropdown'
     * @param {string} props.menuButtonClassName - A custom CSS class for the menu
     *   button. Optional. Defaults to 'dde-alert__dropdown__menu-button'
     * @param {function} props.listFilterFunction - A filtering function used to
     *   filter the alerts that display in the dropdown. Optional. Defaults to:
     *   alert => alert (i.e. no filtering)
     * @param {boolean} props.dropUp - If true, the menu will drop up instead of
     *   dropping down. Optional. Default: false
     * @param {boolean} props.rightAlign - If true, the right edge of the menu
     *   will align with the right edge of the button. If false, the left edge
     *   of the menu will align with the left edge of the button. Optional.
     *   Default: false
     * @param {function} props.onListItemClick - Event handler for clicking on
     *   a list item.
     */
    constructor(props) {
      super(props);
      this.state = {
        dropdownMenuOpen: false
      };

      this.handleMenuButtonClick = this.handleMenuButtonClick.bind(this);
      this.handleMenuCloseClick = this.handleMenuCloseClick.bind(this);
    }

    /**
     * Main render method
     * @override
     */
    render() {
      return (
        <div
          className={this.getClassName()}
        >
          <div
            className={this.props.menuButtonClassName}
            onClick={this.handleMenuButtonClick}
          >
            {this.renderButtonText()}
          </div>
          <ul className={`dropdown-menu ${this.props.rightAlign ? 'dropdown-menu-right' : ''}`}>
            {this.renderAlerts()}
          </ul>
        </div>
      );
    }

    /**
     * Returns the appropriate CSS class name for the component, factoring in
     * whether it is open/closed, a dropup or dropdown menu, and passed-in
     * custom class names(s).
     * @returns {string} - The CSS class name.
     */
    getClassName() {
      const dropDownOrUp = this.props.dropUp ? 'dropup' : 'dropdown';
      const className = this.props.className;
      const open = this.state.dropdownMenuOpen ? 'open' : '';

      return `${dropDownOrUp} ${className} ${open}`;
    }

    /**
     * Closes the menu by updating the dropdownMenuOpen state to false.
     */
    closeMenu() {
      this.setState({dropdownMenuOpen: false});
    }

    /**
     * Returns the button text. If button text is a function, passes the function
     * the alerts, and renders the result.
     * @returns {string|React.Component} - The button text.
     */
    renderButtonText() {
      if (typeof this.props.buttonText === 'function') {
        return this.props.buttonText(this.props.alerts);
      }

      return this.props.buttonText;
    }

    /**
     * Renders all alerts as <ListItemAlert /> components, first applying the
     * this.props.listFilterFunction filter.
     * @returns {React.Component[]} - The <ListItemAlert /> components.
     */
    renderAlerts() {
      return this.props.alerts.filter(this.props.listFilterFunction).map(alert => {
        return (
          <DoradoAlerts.ListItemAlert
            key={alert.alertId}
            id={alert.alertId}
            level={alert.level}
            name={alert.name}
            onClick={this.props.onListItemClick}
          />
        );
      });
    }

    /**
     * Event handler for the menu button. Toggles the state of dropdownMenuOpen,
     * and registers a click handler on the document, so that the menu will be
     * closed on the next click.
     */
    handleMenuButtonClick() {
      this.setState(previousState => {
        return {
          dropdownMenuOpen: !previousState.dropdownMenuOpen
        };
      }, () => {
        document.addEventListener('click', this.handleMenuCloseClick);
      });
    }

    /**
     * Handles the closing of a menu. Called by the event handler registered in
     * this.handleMenuButtonClick(). Also removes said event handler.
     * @param {Event} event - The Event object
     */
    handleMenuCloseClick(event) {
      event.preventDefault();
      this.closeMenu();
      document.removeEventListener('click', this.handleMenuCloseClick);
    }
  }

  AlertDropdown.propTypes = {
    alerts: PropTypes.arrayOf(PropTypes.shape({
      alertId: PropTypes.string.isRequired,
      level: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    })).isRequired,
    buttonText: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func
    ]).isRequired,
    className: PropTypes.string,
    menuButtonClassName: PropTypes.string,
    listFilterFunction: PropTypes.func,
    dropUp: PropTypes.bool,
    rightAlign: PropTypes.bool,
    onListItemClick: PropTypes.func.isRequired
  };

  AlertDropdown.defaultProps = {
    className: 'dde-alert__dropdown',
    menuButtonClassName: 'dde-alert__dropdown__menu-button',
    buttonText: alerts => `Total: ${alerts.length}`,
    listFilterFunction: alert => alert,
    dropUp: false,
    rightAlign: false
  };

  return AlertDropdown;

})();
