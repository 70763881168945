window.DoradoAlerts = window.DoradoAlerts || {};

window.DoradoAlerts.ListItemAlert = (function() {

  /**
   * An alert displayed in an <AlertDropdown /> component.
   * @param {object} props - Props passed to the <ListItemAlert /> component
   * @param {number} props.id - The id of the alert.
   * @param {number} props.level - The severity level of the alert. 1 - Error,
   *   2 - Warning, 3 - Info. This affects the icon shown on the alert.
   * @param {string} props.name - The name of the alert. The name is displayed as the
   *   list item text.
   * @param {function} props.onClick - Event handler for clicking on the list item
   * @returns {React.Component} - The <ListItemAlert /> component.
   */
  function ListItemAlert({
    id,
    level,
    name,
    onClick
  }) {
    return (
      <li className="dde-alert__dropdown__list__item">
        <a href="#" onClick={handleListItemClick}>
          <DoradoAlerts.AlertLevelIcon
            level={level}
            className="dde-alert__dropdown__list__item__icon"
          />
          {name}
        </a>
      </li>
    );

    /**
     * Handler for clicking on a list item. Calls onClick, passing along the
     * ID of the clicked alert
     * @param {Event} event - The Event object.
     */
    function handleListItemClick(event) {
      onClick(id, event);
    }

  }

  ListItemAlert.propTypes = {
    id: PropTypes.string.isRequired,
    level: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
  };

  return ListItemAlert;

})();
