window.DoradoAlerts = window.DoradoAlerts || {};

window.DoradoAlerts.AlertModal = (function() {

  /**
   * Displays a Bootstrap/jQuery modal using the passed-in props.
   * TODO This component is dependent on Bootstrap Modal jQuery plugin,
   * and uses a `ref` hack to display the alert. When this project
   * is ported to it's own project and ES6 modules are available, this logic
   * should be replaced with a React-compatible Bootstrap modal (something like
   * @see https://www.npmjs.com/package/react-bootstrap-modal)
   * @param {props} props - Props passed to the <AlertModal /> component
   * @param {string} props.name - The name of the alert. Displayed in the modal header
   * @param {number} props.level - The severity level of the alert.
   * @param {string} props.detail - Detailed information about the alert. This will
   *   be displayed in the body of the alert modal.
   * @param {function} props.onCloseClick - Event handler for clicking on the close button
   * @returns {React.Component} - The <AlertModal /> component.
   */
  function AlertModal({
    name,
    level,
    detail,
    onCloseClick
  }) {
    // The ref property below takes a callback, which is called when the component
    // mounts, and is passed a reference to the DOMElement that was created.
    // In this callback, the DOMElement is converted to a jQuery object, and the
    // modal is triggered.
    return (
      <div
        className="modal fade dde-alert__modal"
        id="alert-modal"
        ref={div => $(div).modal({backdrop: false})}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">
                <DoradoAlerts.AlertLevelIcon
                  level={level}
                  className="dde-alert__modal__icon"
                />
                {name}
              </h4>
            </div>
            <div className="modal-body" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(detail) }} />
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                id="alert-modal-close"
                onClick={onCloseClick}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    );

  }

  AlertModal.propTypes = {
    name: PropTypes.string.isRequired,
    level: PropTypes.number.isRequired,
    detail: PropTypes.string.isRequired,
    onCloseClick: PropTypes.func.isRequired
  };

  return AlertModal;
})();
