window.DoradoAlerts = window.DoradoAlerts || {};

window.DoradoAlerts.AlertLevelIcon = (function() {

  /**
   * Displays an appropriate icon for the alert's severity level
   * @param {*} props - Props passed to <AlertLevelIcon />
   * @param {number} props.level - The severity level of the alert. 1 - Error,
   *   2 - Warning, 3 - Info. This affects the icon shown on the alert, and its 
   *   color.
   * @param {string} props.className - A custom CSS class to use. Optional. 
   *   Defaults to 'dde-alert__icon'
   * @returns {React.Component} - The <AlertLevelIcon /> component.
   */
  function AlertLevelIcon({
    level,
    className = 'dde-alert__icon'
  }) {
    return (
      <div className={className}>
        <span className={`glyphicon ${getIconClass(level)}`}></span>
      </div>
    );

    /**
     * Returns the correct glyphicon CSS class for the level. Uses 
     * glyphicon-info-sign if there is no mapped CSS class for a level.
     * @param {number} level - The severity level.
     * @returns {string} - The glyphicon CSS class.
     */
    function getIconClass(level) {
      return {
        3: 'glyphicon-info-sign',
        2: 'glyphicon-warning-sign',
        1: 'glyphicon-exclamation-sign'
      }[level] || 'glyphicon-info-sign';
    }
  }

  AlertLevelIcon.propTypes = {
    level: PropTypes.number.isRequired,
    className: PropTypes.string
  };

  return AlertLevelIcon;

})();