const DoradoAlerts = window.DoradoAlerts || {};

/**
 * Initialization function for Dorado Alerts. This function will render the 
 * <AlertContainer /> component on rootElementId, passing along config
 * options.
 * @param {object} config - The configuration object
 * @param {string} rootElementId - The ID of the DOM Element to render
 *   the <AlertContainer /> in.
 */
DoradoAlerts.initialize = function initialize(config = {}, rootElementId) {
  ReactDOM.render((
    <DoradoAlerts.AlertContainer 
      endpoint={config.endpoint} 
      alertRotationInterval={config.alertRotationInterval}
      alertRefreshInterval={config.alertRefreshInterval}
      nonExpiringAlertCookieAge={config.nonExpiringAlertCookieAge}
    />
  ), document.getElementById(rootElementId));
};