window.DoradoAlerts = window.DoradoAlerts || {};

window.DoradoAlerts.OverlayAlertDropdown = (function() {

  /**
   * Wraps the <AlertDropdown /> component, intended for overlaying over the
   * screen when all alerts are hidden.
   * @param {*} props - Props passed to the <OverlayAlertDropdown /> component
   * @param {object[]} props.alerts - The alerts to display in the dropdown.
   * @param {function} onListItemClick - Event handler for clicking on a list item.
   * @returns {React.Component} - The <OverlayAlertDropdown /> component
   */
  function OverlayAlertDropdown({
    alerts,
    onListItemClick
  }) {
    return (
      <DoradoAlerts.AlertDropdown
        alerts={alerts}
        onListItemClick={onListItemClick}
        className="dde-alert__dropdown dde-alert__dropdown--overlay"
        buttonText={renderButtonText}
        rightAlign={true}
      />
    );

    /**
     * Renders an expanded and condensed version of the button text. The expanded
     * version displays the alert count with the label 'Alerts'.
     * The condensed version only displays the alert count.
     * @param {object[]} alerts - The alerts in the dropdown
     * @returns {React.Component} - The rendered button text components
     */
    function renderButtonText(alerts) {
      const alertCount = alerts.length;
      const alertCountLabel = alerts.length > 1 ? 'alerts' : 'alert';
      return (
        <div>
          <span
            className="glyphicon glyphicon-warning-sign dde-alert__dropdown__menu-button__icon"
          />
          <span
            className="dde-alert__dropdown__menu-button__text dde-alert__dropdown__menu-button__text--expanded"
          >
            {`${alertCount} ${alertCountLabel}`}
          </span>
          <span
            className="dde-alert__dropdown__menu-button__text dde-alert__dropdown__menu-button__text--condensed"
          >
            {alertCount}
          </span>
        </div>
      );
    }
  }

  OverlayAlertDropdown.propTypes = {
    alerts: PropTypes.arrayOf(PropTypes.shape({
      alertId: PropTypes.string.isRequired,
      level: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    })).isRequired,
    onListItemClick: PropTypes.func.isRequired
  };

  return OverlayAlertDropdown;

})();
