window.DoradoAlerts = window.DoradoAlerts || {};

window.DoradoAlerts.Alert = (function() {

  /**
   * Used to display non-hidden alerts.
   * @param {*} props - Props passed to the <Alert /> component
   * @param {number} props.id - The id of the alert.
   * @param {number} props.level - The severity level of the alert. 1 - Error,
   *   2 - Warning, 3 - Info. This affects the icon shown on the alert, and its
   *   color.
   * @param {string} props.name - The name of the alerts. The name is displayed in the alert.
   * @param {object[]} props.alerts - A reference to all alert objects.
   * @param {number} props.activeAlertIndex - The index of the alert in alerts that is currently
   *   active (i.e. being displayed on the screen)
   * @param {function} props.onNameClick - Event handler for clicking on the alert name.
   * @param {function} props.onCloseClick - Event handler for clicking on the close icon.
   * @param {function} props.onMouseOver - Event handler for mousing over the alert.
   * @param {function} props.onMouseLeave - Event handler for mousing out of the alert.
   * @returns {React.Component} - The <Alert /> component.
   */
  function Alert({
    id,
    level,
    name,
    active = false,
    alerts,
    activeAlertIndex,
    onNameClick,
    onCloseClick,
    onMouseOver,
    onMouseLeave
  }) {
    return (
      <div
        className={`dde-alert ${getAlertClasses()}`}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
      >
        <DoradoAlerts.AlertLevelIcon level={level} />
        <div className="dde-alert__name">
          <a href="#" className="dde-alert__more-info-link" onClick={handleNameClick}>
            {name}
          </a>
        </div>
        <DoradoAlerts.InlineAlertDropdown
          activeAlertIndex={activeAlertIndex}
          alerts={alerts}
          onListItemClick={onNameClick}
        />
        <div className="dde-alert__close">
          <a href="#" className="dde-alert__close__link" onClick={handleCloseClick}>
            <span className="glyphicon glyphicon-remove"></span>
          </a>
        </div>
      </div>
    );

    /**
     * Returns the appropriate CSS classes according to the level, and whether the
     * alert is active or not. If the level does not match any of the configured
     * alert classes, the class 'dde-alert--info' will be used
     * @returns {string} - The alert CSS class
     */
    function getAlertClasses() {
      const levelClass = {
        3: 'dde-alert--info',
        2: 'dde-alert--warn',
        1: 'dde-alert--error'
      }[level] || 'dde-alert--info';

      return `${levelClass} ${active ? 'dde-alert--active' : ''}`;
    }

    /**
     * Handler for clicking on the name. Calls onNameClick, passing along
     * the ID of the clicked alert
     * @param {Event} event - The Event object
     */
    function handleNameClick(event) {
      onNameClick(id, event);
    }

    /**
     * Handler for clicking on the close icon. Calls onCloseClick, passing along
     * the ID of the clicked alert
     * @param {Event} event - The Event object
     */
    function handleCloseClick(event) {
      onCloseClick(id, event);
    }
  }

  Alert.propTypes = {
    id: PropTypes.string.isRequired,
    level: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    active: PropTypes.bool,
    alerts: PropTypes.arrayOf(PropTypes.shape({
      alertId: PropTypes.string.isRequired,
      level: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    })).isRequired,
    activeAlertIndex: PropTypes.number.isRequired,
    onNameClick: PropTypes.func.isRequired,
    onCloseClick: PropTypes.func.isRequired,
    onMouseOver: PropTypes.func.isRequired,
    onMouseLeave: PropTypes.func.isRequired
  };

  return Alert;
})();
