window.DoradoAlerts = window.DoradoAlerts || {};

DoradoAlerts.AlertContainer = (function() {

  /**
   * The main container for Dorado Alerts, containing alert logic and state
   */
  class AlertContainer extends React.Component {

    /**
     * The <AlertContainer /> constructor
     * @param {object} props - <AlertContainer /> props
     * @param {string} props.endpoint - The HTTP endpoint to fetch alerts from
     * @param {number} props.alertRotationInterval - The interval (in ms) between when
     *   one alert rotates to another one. Optional. Defaults to 10000 (10
     *   seconds).
     * @param {number} props.alertRefreshInterval - The interval (in ms) between when
     *   alerts downloaded become stale. When alerts are stale, they will be
     *   refreshed on the next rotation. Optional. Defaults to 30000 (5 minutes).
     * @param {number} props.nonExpiringAlertCookieAge - The maxAge (in ms) that will be assigned
     *   to a close cookie set for a non-expiring alert. Defaults to 604800000 (7 days)
     */
    constructor(props) {
      super(props);

      this.state = {
        alerts: [],
        activeAlertIndex: 0,
        modalAlertId: null
      };

      this.CLOSE_COOKIE_NAMESPACE = 'dorado-alert-cookie-close';

      this.serverTime = null;
      this.rotationTimer = null;
      this.expirationTimers = {};
      this.alertsStale = null;

      this.handleAlertNameClick = this.handleAlertNameClick.bind(this);
      this.handleCloseClick = this.handleAlertCloseClick.bind(this);
      this.handleAlertMouseOver = this.handleAlertMouseOver.bind(this);
      this.handleAlertMouseLeave = this.handleAlertMouseLeave.bind(this);
      this.handleModalCloseClick = this.handleModalCloseClick.bind(this);
    }

    /**
     * Main render method. Called when state changes.
     * @override
     */
    render() {
      this.addBodyClass();
      return (
        <div className="dde-alert__container">
          {this.renderAlerts()}
          {this.renderOverlayAlertDropdown()}
          {this.renderAlertModal()}
        </div>
      );
    }

    /**
     * Uses jQuery to toggle a `dde-alerts-visible` or `dde-alerts-overlay-visible`
     * class on the <body> element of the document, depending on whether the alerts
     * bar or alerts overlay is displayed.
     * TODO before porting this component to a separate project, the dependency
     * on jQuery should be removed.
     */
    addBodyClass() {
      // Reset by removing both classes
      const $body = $('body');
      $('body').removeClass('dde-alerts-visible dde-alerts-overlay-visible');

      // If we do not have alerts, do not add any body classes
      if (this.state.alerts.length === 0) {
        return;
      }

      // If we have visible alerts, add the dde-alerts-visible class
      // If not, add the dde-alerts-overlay-visible class
      const areAlertsVisible = this.state.alerts.filter(this.isAlertVisible).length > 0;
      if (areAlertsVisible) {
        $body.addClass('dde-alerts-visible');
      } else {
        $body.addClass('dde-alerts-overlay-visible');
      }
    }

    /**
     * Returns true if the alert is not hidden
     * @param {object} alert - An alert object
     * @returns {boolean} - True if alert is not hidden
     */
    isAlertVisible(alert) {
      return !alert.hidden;
    }

    /**
     * Renders all non-hidden alerts in the state
     * @returns {React.Component[]} - An array of <Alert /> components
     */
    renderAlerts() {
      return this.state.alerts
        .filter(this.isAlertVisible)
        .map(alert => {
          return (
            <DoradoAlerts.Alert
              key={alert.alertId}
              id={alert.alertId}
              level={alert.level}
              name={alert.name}
              active={alert.active}
              alerts={this.state.alerts}
              activeAlertIndex={this.state.activeAlertIndex}
              onNameClick={this.handleAlertNameClick}
              onCloseClick={this.handleCloseClick}
              onMouseOver={this.handleAlertMouseOver}
              onMouseLeave={this.handleAlertMouseLeave}
            />
          );
        });
    }

    /**
     * Renders the <AlertModal /> component if there is an active modal
     * @returns {React.Component|null} - An <AlertModal /> component
     */
    renderAlertModal() {
      if (!this.state.modalAlertId) {
        return null;
      }

      const modalAlert = this.state.alerts.find(alert => alert.alertId === this.state.modalAlertId);

      if (!modalAlert) {
        return null;
      }

      return (
        <DoradoAlerts.AlertModal
          name={modalAlert.name}
          level={modalAlert.level}
          detail={modalAlert.detail}
          onCloseClick={this.handleModalCloseClick}
        />
      );
    }

    renderOverlayAlertDropdown() {
      // If we have alerts and they are all closed, show the overlay dropdown
      if (this.state.alerts.length && !this.state.alerts.filter(this.isAlertVisible).length) {
        return (
          <DoradoAlerts.OverlayAlertDropdown
            alerts={this.state.alerts}
            onListItemClick={this.handleAlertNameClick}
          />
        );
      }

      return null;
    }

    /**
     * Lifecycle method that runs after the component first mounts. Alerts are
     * initially fetched from the API here.
     * @override
     */
    componentDidMount() {
      // Initial fetching of alerts
      this.fetchAlerts()
        .then((fetchedAlerts) => {
          this.setState(
            DoradoAlerts.alertProcessor.getStateFromFetchedAlerts(fetchedAlerts, this.CLOSE_COOKIE_NAMESPACE),
            () => {
              this.startAlertRotation();
              this.setAlertExpirationTimers();
              this.startAlertRefreshTimer();
            }
          );
        });
    }

    /**
     * Fetches alerts from the API endpoint using jQuery's .ajax() utility.
     * Sets the current server time in the component context.
     * TODO before porting this component to a separate project, the dependency
     * on jQuery should be removed.
     * @returns {jqXHR} - A jQuery promise resolving with the fetched alerts
     */
    fetchAlerts() {
      const timestamp = new Date().getTime();
      // IE10 and below aggressively caches XHR responses. Append the current timestamp
      // to the URL in order to bust the cache.
      return $.ajax(`${this.props.endpoint}?${timestamp}`)
        .then((alerts, status, xhr) => {
          this.serverTime = xhr.getResponseHeader('serverTime');

          return alerts;
        })
        .fail(error => {
          JL().error('Error loading alerts', error);
        });
    }

    /**
     * Start the alert rotation timer, rotating alerts on the
     * props.alertRotationInterval
     */
    startAlertRotation() {
      this.rotationTimer = setInterval(() => {
        if (this.alertsStale) {
          this.refreshAlerts();
          return;
        }

        this.setState(previousState => {
          return DoradoAlerts.alertProcessor.activateNextAlert(previousState);
        });
      }, this.props.alertRotationInterval);
    }

    /**
     * Sets a timer to close the alert for each alert having an expiresAt property.
     * The time-to-live on the timer is the difference between the expiration time
     * and the server time.
     */
    setAlertExpirationTimers() {
      this.state.alerts.forEach(alert => {
        // Do not set a timer if the alert does not expire
        if (!alert.expiresAt) {
          return;
        }

        // Do not set a timer if a valid TTL cannot be calculated
        if (!this.serverTime || alert.expiresAt - this.serverTime < 0) {
          return;
        }

        const timeToLive = alert.expiresAt - this.serverTime;

        this.expirationTimers[alert.alertId] = setTimeout(() => {
          this.closeAlert(alert.alertId);
        }, timeToLive);
      });
    }

    /**
     * Clears all alert expiration timers.
     */
    cancelAlertExpirationTimers() {
      Object.keys(this.expirationTimers).forEach(timer => {
        clearTimeout(timer);
      });
      this.expirationTimers = {};
    }

    /**
     * Starts the interval timer to mark alerts as stale, using the interval
     * provided by this.props.alertRefreshInterval
     */
    startAlertRefreshTimer() {
      this.refreshTimer = setInterval(() => {
        this.alertsStale = true;
      }, this.props.alertRefreshInterval);
    }

    /**
     * Fetches alerts from the API, applying previously hidden alerts to the new
     * alerts. Note that due to the many ways that refreshed alerts can differ from
     * stale alerts, the alerts will resume rotation from the beginning after refreshing.
     */
    refreshAlerts() {
      this.cancelAlertExpirationTimers();
      this.fetchAlerts()
        .then(fetchedAlerts => {
          this.setState(
            DoradoAlerts.alertProcessor.getStateFromFetchedAlerts(fetchedAlerts, this.CLOSE_COOKIE_NAMESPACE),
            () => {
              // After refreshing, reset expiration timers.
              this.setAlertExpirationTimers();
              this.alertsStale = false;
            }
          );
        });
    }

    /**
     * Handles clicking of the alert name on an <Alert />, updating the
     * modalAlertId property of the state to the clicked alert.
     * TODO Showing the alert is dependent on Bootstrap Modal jQuery plugin,
     * and uses a `ref` hack on the alert modal to work. When this project
     * is ported to it's own project and ES6 modules are available, this logic
     * should be replaced with a React-compatible Bootstrap modal (something like
     * @see https://www.npmjs.com/package/react-bootstrap-modal)
     * @param {number} id - The alert id that was clicked
     * @param {Event} event - The Event object
     */
    handleAlertNameClick(id, event) {
      event.preventDefault();
      this.stopAlertRotation();
      this.setState({modalAlertId: id});
    }

    /**
     * Closes the clicked alert
     * @param {number} id - Id of the alert to close
     * @param {Event} event - The Event object
     */
    handleAlertCloseClick(id, event) {
      event.preventDefault();
      this.closeAlert(id);
    }

    /**
     * Closes an alert by setting it's hidden property to true. Also finds the next
     * possible alert to display, updating its active property to true.
     * @param {number} closedAlertId - Id of the alert to close.
     */
    closeAlert(closedAlertId) {
      this.stopAlertRotation();
      this.setState(previousState => {
        const closedAlert = previousState.alerts.find(alert => alert.alertId === closedAlertId);
        this.setCloseCookie(closedAlert);

        return DoradoAlerts.alertProcessor.getUpdatedAlertStateOnClose(previousState, closedAlert);
      },
        // After updating state, restart the rotation
        () => {
          this.startAlertRotation();
        });
    }

    /**
     * Stops the alert rotation timer.
     */
    stopAlertRotation() {
      clearInterval(this.rotationTimer);
      this.rotationTimer = null;
    }

    /**
     * Handles a mouseOver event on an <Alert /> component by stopping the alert
     * rotation timer.
     */
    handleAlertMouseOver() {
      this.stopAlertRotation();
    }

    /**
     * Handles a mouseLeave event on an <Alert /> component by restarting the alert
     * rotation, if a modal is not currently open.
     */
    handleAlertMouseLeave() {
      if (!this.state.modalAlertId) {
        this.startAlertRotation();
      }
    }

    /**
     * Handles a click event on an <AlertModal /> close button. Uses jQuery to hide the
     * modal, and then updates the state to nullify the modalAlertId, causing it to be
     * removed when rendering.
     * TODO before porting this component to a separate project, the dependency on the
     * Bootstrap Modal jQuery plugin should be removed, and replaced with a React-
     * compatible Bootstrap modal (@see https://www.npmjs.com/package/react-bootstrap-modal)
     */
    handleModalCloseClick() {
      $('#alert-modal').modal('hide');
      this.setState({modalAlertId: null}, () => this.startAlertRotation());
    }

    /**
     * Sets a cookie indicating that the alert has been closed. Sets an expiration time
     * on the cookie, equal to the amount of time remaining before the expiresAt time,
     * or the value of this.props.nonExpiringAlertCookieAge if the cookie does not expire.
     * @param {object} alert - An alert object.
     */
    setCloseCookie(alert) {
      const cookieAge = alert.expiresAt ? alert.expiresAt - this.serverTime : this.props.nonExpiringAlertCookieAge;
      const expires = new Date(new Date().getTime() + cookieAge);
      const cookieName = `${this.CLOSE_COOKIE_NAMESPACE}-${alert.alertId}`;
      Cookies.set(cookieName, 1, {expires});
    }

  }

  AlertContainer.propTypes = {
    endpoint: PropTypes.string.isRequired,
    alertRotationInterval: PropTypes.number,
    alertRefreshInterval: PropTypes.number,
    nonExpiringAlertCookieAge: PropTypes.number
  };

  AlertContainer.defaultProps = {
    alertRotationInterval: 10000, // Every 10 seconds
    alertRefreshInterval: 300000, // Every 5 minutes
    nonExpiringAlertCookieAge: 604800000 // 7 days
  };

  return AlertContainer;
})();
