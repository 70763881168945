window.DoradoAlerts = window.DoradoAlerts || {};

window.DoradoAlerts.InlineAlertDropdown = (function() {

  /**
   * Wraps the <AlertDropdown /> component, intended for use inside the <Alert />
   * component.
   * @param {object} props - Props passed to the <InlineAlertDropdown /> component
   * @param {object[]} props.alerts - The alerts to display in the dropdown.
   * @param {function} onListItemClick - Event handler for clicking on a list item.
   * @returns {React.Component} - The <InlineAlertDropdown /> component
   */
  function InlineAlertDropdown({
    alerts,
    onListItemClick
  }) {
    // Do not display the badge/dropdown if there are less than 2 alerts.
    if (alerts.length < 2) {
      return null;
    }

    return (
      <DoradoAlerts.AlertDropdown
        alerts={alerts}
        onListItemClick={onListItemClick}
        className="dde-alert__dropdown dde-alert__dropdown--inline"
        menuButtonClassName="dde-alert__dropdown__menu-button badge"
        buttonText={renderButtonText}
        listFilterFunction={alert => !alert.active}
      />
    );

    /**
     * Renders an expanded and condensed version of the button text. The expanded
     * version displays the alert count minus 1 formatted as so: + N more.
     * The condensed version displays only the total count of alerts.
     * @param {object[]} alerts - The alerts in the dropdown
     * @returns {React.Component} - The rendered button text components
     */
    function renderButtonText(alerts) {
      return (
        <div>
          <span className="dde-alert__dropdown__menu-button__text dde-alert__dropdown__menu-button__text--expanded">
            {`+ ${alerts.length - 1} more`}
          </span>
          <span className="dde-alert__dropdown__menu-button__text dde-alert__dropdown__menu-button__text--condensed">
            {alerts.length}
          </span>
        </div>
      );
    }
  }

  InlineAlertDropdown.propTypes = {
    alerts: PropTypes.arrayOf(PropTypes.shape({
      alertId: PropTypes.string.isRequired,
      level: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    })).isRequired,
    onListItemClick: PropTypes.func.isRequired
  };

  return InlineAlertDropdown;

})();
