window.DoradoAlerts = window.DoradoAlerts || {};

/**
 * Module containing functions for processing changes in <AlertContainer /> state.
 */
DoradoAlerts.alertProcessor = (function() {

  const alertProcessor = {

    /**
     * Given alerts fetched from the API, returns the states `alerts` and `activeAlertIndex`,
     * applying the hidden property to any alerts previously closed (by checking close cookies),
     * and calculating and applying the active property to the first active alert.
     * @param {object} fetchedAlerts - Alerts fetched from the api
     * @param {string} closeCookieNamespace - Cookie namespace to use when searching
     *   for previously closed alerts.
     * @returns {object} - The initial `alerts` and `activeAlertIndex` state.
     */
    getStateFromFetchedAlerts(fetchedAlerts, closeCookieNamespace) {
      // Apply hidden property to alerts that were previously closed
      const alertsWithHiddenApplied = fetchedAlerts
        .map(this.mapAlertsWithHiddenApplied(
          alert => this.hasCloseCookie(alert.alertId, closeCookieNamespace)
        ));

      // Find and activate the first alert
      const firstActiveAlertIndex = this.findNextActiveAlertIndex(alertsWithHiddenApplied);
      const alertsWithActiveApplied = alertsWithHiddenApplied
        .map(this.mapAlertsWithActiveApplied(firstActiveAlertIndex));
      return {
        alerts: alertsWithActiveApplied,
        activeAlertIndex: firstActiveAlertIndex
      };
    },

    /**
     * Given the previous states of `alerts` and `activeAlertIndex`, calculates the next
     * active alert, and returns the new `alerts` and `activeAlertIndex` states.
     * @param {object} previousState - The previous state of <AlertContainer />
     * @param {object[]} previousState.alerts - The previous `alerts` state
     * @param {number} previousState.activeAlertIndex - The previous `activeAlertIndex`
     *   state.
     * @returns {object} - The new states of `alerts` and `activeAlertIndex`
     */
    activateNextAlert({alerts, activeAlertIndex}) {
      const nextIndex = this.findNextActiveAlertIndex(
        alerts,
        activeAlertIndex
      );

      // If next alert is the same index as the previous alert, we don't need
      // to rotate
      if (nextIndex === activeAlertIndex) {
        return {};
      }

      return {
        alerts: alerts
          .map(this.mapAlertsWithActiveApplied(nextIndex)),
        activeAlertIndex: nextIndex
      };
    },

    /**
     * Given the previous states of `alerts` and `activeAlertIndex` and the ID
     * of the closed alert, returns new `alerts` and `activeAlertIndex` states,
     * with the hidden property applied to the closed alert, and the calculated
     * next alert set as active (if the closed alert was active)
     * @param {object} previousState - The previousState of the <AlertContainer />
     * @param {object[]} previousState.alerts - The previous `alerts` state
     * @param {number} previousState.activeAlertIndex - The previous `activeAlertIndex`
     *   state.
     * @param {object} closedAlert - The alert that was closed
     * @return {object} - The new states of `alerts` and `activeAlertIndex`
     */
    getUpdatedAlertStateOnClose({alerts, activeAlertIndex}, closedAlert) {
      // Update the alerts, setting the closed alert to hidden
      const alertsWithHiddenApplied = alerts
        .map(this.mapAlertsWithHiddenApplied(alert => alert.alertId === closedAlert.alertId));

      // If the closed alert was active, find the next active alert
      let nextIndex;
      if (closedAlert && closedAlert.active) {
        nextIndex = this.findNextActiveAlertIndex(alertsWithHiddenApplied, activeAlertIndex);
      } else {
        nextIndex = activeAlertIndex;
      }

      return {
        alerts: alertsWithHiddenApplied
          .map(this.mapAlertsWithActiveApplied(nextIndex)),
        activeAlertIndex: nextIndex
      };
    },

    /**
     * Iterates through an array of alerts, starting after the activeAlertIndex,
     * searching for the first non-hidden alert. Iteration will loop to the
     * beginning of the array when reaching the end. If a non-hidden alert is found,
     * its index will be returned. Otherwise, null will be returned.
     * NOTE: This function will return the index of the current alert if it
     * happens to be the only non-hidden alert.
     * @param {object[]} alerts - An array of alerts to iterate through
     * @param {number} activeAlertIndex - The position in the array to begin
     *   searching after. Optional. If not provided, begins searching from
     *   the beginning of the array
     * @returns {number|null} - The index of the next non-hidden alert, if found.
     */
    findNextActiveAlertIndex(alerts, activeAlertIndex = -1) {
      for (let index = 1; index < alerts.length + 1; index++) {
        let offsetIndex = (index + activeAlertIndex) % alerts.length;
        let alert = alerts[offsetIndex];

        if (!alert.hidden) {
          return offsetIndex;
        }
      }

      return null;
    },

    /**
     * Returns a mapping function used to apply an active status at the supplied
     * index, while deactivating other active alerts.
     * @param {number} indexToActivate - An alert index to activate. The alert
     *   at this index will have its active property set to `true`, all other
     *   active alerts will be set to `false`
     * @returns {function} - The mapping function
     */
    mapAlertsWithActiveApplied(indexToActivate) {
      return (alert, index) => {
        // Activate the alert matching indexToActivate
        if (index === indexToActivate) {
          return Object.assign({}, alert, {active: true});
        }

        // Deactivate any other active alerts
        if (alert.active) {
          return Object.assign({}, alert, {active: false});
        }

        return alert;
      };
    },

    /**
     * Returns a mapping function, applying the active: false and hidden: true
     * properties to alerts, if the applyCondition function returns true
     * @param {function} applyCondition - A function that takes the current iterated
     *   alert. If the function returns truthy, the alert will be deactivated
     *   and hidden.
     * @returns {function} - The mapping function
     */
    mapAlertsWithHiddenApplied(applyCondition) {
      return alert => {
        if (applyCondition(alert)) {
          return Object.assign({}, alert, {active: false, hidden: true});
        }

        return alert;
      };
    },

    /**
     * Returns true if a close cookie exists for the given alert id.
     * @param {number} id - The alert id of the cookie to check
     * @param {string} closeCookieNamespace - Cookie namespace to use when checking
     *   for cookie existence.
     * @returns {boolean} - True if the cookie exists
     */
    hasCloseCookie(id, closeCookieNamespace) {
      const cookieName = `${closeCookieNamespace}-${id}`;
      return !!Cookies.get(cookieName);
    }
  };

  return alertProcessor;

})();
